var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid p-0"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-flex d-flex"},[_c('div',{staticClass:"card illustration flex-fill"},[_c('div',{staticClass:"card-body p-0 d-flex flex-fill"},[_c('div',{staticClass:"row no-gutters w-100"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"illustration-text p-3 m-1"},[_c('h4',{staticClass:"illustration-text"},[_vm._v(" Welcome Back, "+_vm._s(_vm.userProfile.profile.firstName)+" ")])])]),_vm._m(1)])])])]),_c('div',{staticClass:"col-12 col-md-6 col-flex d-flex"},[_c('div',{staticClass:"card flex-fill"},[(_vm.project.objectID)?_c('div',{staticClass:"card-body py-4"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.apiCalls))]),_c('p',{staticClass:"mb-2"},[_vm._v("API Calls")]),_c('div',{staticClass:"mb-0"},[_c('span',{class:{
                    badge: true,
                    'badge-soft-success': _vm.apiCallsMonthOverMonth >= 0,
                    'badge-soft-danger': _vm.apiCallsMonthOverMonth < 0,
                    'mr-2': true,
                  }},[_c('i',{staticClass:"mdi mdi-arrow-bottom-right"}),_vm._v(" "+_vm._s(_vm.apiCallsMonthOverMonth >= 0 ? "+" + _vm.apiCallsMonthOverMonth : _vm.apiCallsMonthOverMonth)+"% ")]),_c('span',{staticClass:"text-muted"},[_vm._v("Since last month")])])]),_c('div',{staticClass:"d-inline-block ml-3"},[_c('div',{staticClass:"stat"},[_c('i',{class:{
                    fas: true,
                    'fa-server': true,
                    'fa-2x': true,
                    'text-success': _vm.fakeUsage < 50,
                    'text-warning': _vm.fakeUsage >= 50 && _vm.fakeUsage < 75,
                    'text-danger': _vm.fakeUsage >= 75,
                  }})])])])]):_c('div',{staticClass:"card-body py-4"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-message"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Please select a project. ")])])],1)])]),_c('div',{staticClass:"col-12 col-md-6 col-flex d-flex"},[_c('div',{staticClass:"card flex-fill"},[(_vm.project.objectID)?_c('div',{staticClass:"card-body py-4"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.fake))]),_c('p',{staticClass:"mb-2"},[_vm._v("Fake Records")]),_c('div',{staticClass:"mb-0"},[_c('span',{class:{
                    badge: true,
                    'badge-soft-success': _vm.fakeMonthOverMonth >= 0,
                    'badge-soft-danger': _vm.fakeMonthOverMonth < 0,
                    'mr-2': true,
                  }},[_c('i',{staticClass:"mdi mdi-arrow-bottom-right"}),_vm._v(" "+_vm._s(_vm.fakeMonthOverMonth >= 0 ? "+" + _vm.fakeMonthOverMonth : _vm.fakeMonthOverMonth)+"% ")]),_c('span',{staticClass:"text-muted"},[_vm._v("Since last month")])])]),_c('div',{staticClass:"d-inline-block ml-3"},[_c('div',{staticClass:"stat"},[_c('i',{class:{
                    fas: true,
                    'fa-user': true,
                    'fa-2x': true,
                    'text-success': _vm.fakeUsage < 50,
                    'text-warning': _vm.fakeUsage >= 50 && _vm.fakeUsage < 75,
                    'text-danger': _vm.fakeUsage >= 75,
                  }})])])])]):_c('div',{staticClass:"card-body py-4"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-message"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Please select a project. ")])])],1)])]),_c('div',{staticClass:"col-12 col-md-6 col-flex d-flex"},[_c('div',{staticClass:"card flex-fill"},[(_vm.project.objectID)?_c('div',{staticClass:"card-body py-4"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body"},[(_vm.storage < 1024)?_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.storage)+" B")]):_vm._e(),(_vm.storage >= 1024 && _vm.storage < 1048576)?_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(Number(_vm.storage / 1024).toFixed(2))+" KiB ")]):_vm._e(),(_vm.storage > 1048576)?_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(Number(_vm.storage / 1048576).toFixed(2))+" MiB ")]):_vm._e(),_c('p',{staticClass:"mb-2"},[_vm._v("Data Storage")]),_c('div',{staticClass:"mb-0"},[_c('span',{class:{
                    badge: true,
                    'badge-soft-success': _vm.storageMonthOverMonth >= 0,
                    'badge-soft-danger': _vm.storageMonthOverMonth < 0,
                    'mr-2': true,
                  }},[_c('i',{staticClass:"mdi mdi-arrow-bottom-right"}),_vm._v(" "+_vm._s(_vm.storageMonthOverMonth >= 0 ? "+" + _vm.storageMonthOverMonth : _vm.storageMonthOverMonth)+"% ")]),_c('span',{staticClass:"text-muted"},[_vm._v("Since last month")])])]),_c('div',{staticClass:"d-inline-block ml-3"},[_c('div',{staticClass:"stat"},[_c('i',{class:{
                    fas: true,
                    'fa-database': true,
                    'fa-2x': true,
                    'text-success': _vm.storageUsage < 50,
                    'text-warning': _vm.storageUsage >= 50 && _vm.storageUsage < 75,
                    'text-danger': _vm.storageUsage >= 75,
                  }})])])])]):_c('div',{staticClass:"card-body py-4"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-message"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Please select a project. ")])])],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8 d-flex"},[_c('div',{staticClass:"card flex-fill w-100"},[_vm._m(2),(_vm.project.objectID)?_c('div',{staticClass:"card-body d-flex w-100 overflow-auto"},[_c('div',{staticClass:"align-self-center chart chart-lg"},[(_vm.loaded)?_c('apexchart',{staticClass:"mt-3",attrs:{"type":"line","height":"300","options":_vm.chartOptions,"series":_vm.series}}):_vm._e()],1)]):_c('div',{staticClass:"card-body"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-message"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Please select a project. ")])])],1)])]),_c('div',{staticClass:"col-12 col-lg-4 d-flex"},[_c('div',{staticClass:"card flex-fill w-100"},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"badge badge-info float-right"},[_vm._v(_vm._s(_vm.ticketsNeedingAttention.length + _vm.ticketsOpen.length))]),_c('h5',{staticClass:"card-title mb-0"},[_vm._v("Open Tickets")])]),_c('div',{staticClass:"card-body overflow-auto tickets"},[_c('b-container',{attrs:{"fluid":""}},[(
                _vm.ticketsNeedingAttention.length > 0 || _vm.ticketsOpen.length > 0
              )?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-list-group',[_vm._l((_vm.ticketsNeedingAttention),function(ticket,index){return _c('b-list-group-item',{key:index,staticClass:"flex-column align-items-start text-left",attrs:{"href":"#"},on:{"click":function($event){return _vm.readTicket(ticket['.key'])}}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" #"+_vm._s(ticket.id)+": "+_vm._s(ticket.subject)+" ")]),_c('small',[_vm._v(_vm._s(_vm.formatDate(_vm.getLastUpdate(ticket.updates).date))+" ")])]),_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(
                        String(_vm.getLastUpdate(ticket.updates).message).substr(
                          0,
                          100
                        ) + '...'
                      )}}),_c('small',{domProps:{"innerHTML":_vm._s(
                        _vm.getLastUpdate(ticket.updates).type === 'new-message'
                          ? _vm.userAuthorName
                          : _vm.getLastUpdate(ticket.updates).author
                      )}})])}),_vm._l((_vm.ticketsOpen),function(ticket,index){return _c('b-list-group-item',{key:index,staticClass:"flex-column align-items-start text-left",attrs:{"href":"#"},on:{"click":function($event){return _vm.readTicket(ticket['.key'])}}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" #"+_vm._s(ticket.id)+": "+_vm._s(ticket.subject)+" ")]),_c('small',[_vm._v(_vm._s(_vm.formatDate(_vm.getLastUpdate(ticket.updates).date))+" ")])]),_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(
                        String(_vm.getLastUpdate(ticket.updates).message).substr(
                          0,
                          100
                        )
                      )}}),_c('small',{domProps:{"innerHTML":_vm._s(
                        _vm.getLastUpdate(ticket.updates).type === 'new-message'
                          ? _vm.userAuthorName
                          : _vm.getLastUpdate(ticket.updates).author
                      )}})])})],2)],1)],1):_vm._e(),(
                _vm.ticketsNeedingAttention.length === 0 &&
                _vm.ticketsOpen.length === 0
              )?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h5',{staticClass:"my-2 text-muted"},[_vm._v("You have no open tickets")])])],1):_vm._e()],1)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2 mb-xl-3"},[_c('div',{staticClass:"title col-auto d-none d-sm-block"},[_c('h3',[_vm._v("Dashboard")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 align-self-end text-right"},[_c('img',{staticClass:"img-fluid illustration-img",attrs:{"src":require("@/assets/img/illustrations/customer-support.png"),"alt":"Customer Support"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title mb-0"},[_vm._v("Project Usage")])])}]

export { render, staticRenderFns }
/* eslint-disable prettier/prettier */
<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="title col-auto d-none d-sm-block">
        <h3>Dashboard</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-flex d-flex">
        <div class="card illustration flex-fill">
          <div class="card-body p-0 d-flex flex-fill">
            <div class="row no-gutters w-100">
              <div class="col-6">
                <div class="illustration-text p-3 m-1">
                  <h4 class="illustration-text">
                    Welcome Back, {{ userProfile.profile.firstName }}
                  </h4>
                </div>
              </div>
              <div class="col-6 align-self-end text-right">
                <img
                  src="@/assets/img/illustrations/customer-support.png"
                  alt="Customer Support"
                  class="img-fluid illustration-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-flex d-flex">
        <div class="card flex-fill">
          <div class="card-body py-4" v-if="project.objectID">
            <div class="media">
              <div class="media-body">
                <h3 class="mb-2">{{ apiCalls }}</h3>
                <p class="mb-2">API Calls</p>
                <div class="mb-0">
                  <span
                    :class="{
                      badge: true,
                      'badge-soft-success': apiCallsMonthOverMonth >= 0,
                      'badge-soft-danger': apiCallsMonthOverMonth < 0,
                      'mr-2': true,
                    }"
                  >
                    <i class="mdi mdi-arrow-bottom-right"></i>
                    {{
                      apiCallsMonthOverMonth >= 0
                        ? "+" + apiCallsMonthOverMonth
                        : apiCallsMonthOverMonth
                    }}%
                  </span>
                  <span class="text-muted">Since last month</span>
                </div>
              </div>
              <div class="d-inline-block ml-3">
                <div class="stat">
                  <i
                    :class="{
                      fas: true,
                      'fa-server': true,
                      'fa-2x': true,
                      'text-success': fakeUsage < 50,
                      'text-warning': fakeUsage >= 50 && fakeUsage < 75,
                      'text-danger': fakeUsage >= 75,
                    }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body py-4" v-else>
            <b-alert variant="info" show
              ><div class="alert-message">
                <i class="fas fa-exclamation-circle"></i> Please select a
                project.
              </div></b-alert
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-flex d-flex">
        <div class="card flex-fill">
          <div class="card-body py-4" v-if="project.objectID">
            <div class="media">
              <div class="media-body">
                <h3 class="mb-2">{{ fake }}</h3>
                <p class="mb-2">Fake Records</p>
                <div class="mb-0">
                  <span
                    :class="{
                      badge: true,
                      'badge-soft-success': fakeMonthOverMonth >= 0,
                      'badge-soft-danger': fakeMonthOverMonth < 0,
                      'mr-2': true,
                    }"
                  >
                    <i class="mdi mdi-arrow-bottom-right"></i>
                    {{
                      fakeMonthOverMonth >= 0
                        ? "+" + fakeMonthOverMonth
                        : fakeMonthOverMonth
                    }}%
                  </span>
                  <span class="text-muted">Since last month</span>
                </div>
              </div>
              <div class="d-inline-block ml-3">
                <div class="stat">
                  <i
                    :class="{
                      fas: true,
                      'fa-user': true,
                      'fa-2x': true,
                      'text-success': fakeUsage < 50,
                      'text-warning': fakeUsage >= 50 && fakeUsage < 75,
                      'text-danger': fakeUsage >= 75,
                    }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body py-4" v-else>
            <b-alert variant="info" show
              ><div class="alert-message">
                <i class="fas fa-exclamation-circle"></i> Please select a
                project.
              </div></b-alert
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-flex d-flex">
        <div class="card flex-fill">
          <div class="card-body py-4" v-if="project.objectID">
            <div class="media">
              <div class="media-body">
                <h3 class="mb-2" v-if="storage < 1024">{{ storage }} B</h3>
                <h3 class="mb-2" v-if="storage >= 1024 && storage < 1048576">
                  {{ Number(storage / 1024).toFixed(2) }} KiB
                </h3>
                <h3 class="mb-2" v-if="storage > 1048576">
                  {{ Number(storage / 1048576).toFixed(2) }} MiB
                </h3>
                <p class="mb-2">Data Storage</p>
                <div class="mb-0">
                  <span
                    :class="{
                      badge: true,
                      'badge-soft-success': storageMonthOverMonth >= 0,
                      'badge-soft-danger': storageMonthOverMonth < 0,
                      'mr-2': true,
                    }"
                  >
                    <i class="mdi mdi-arrow-bottom-right"></i>
                    {{
                      storageMonthOverMonth >= 0
                        ? "+" + storageMonthOverMonth
                        : storageMonthOverMonth
                    }}%
                  </span>
                  <span class="text-muted">Since last month</span>
                </div>
              </div>
              <div class="d-inline-block ml-3">
                <div class="stat">
                  <i
                    :class="{
                      fas: true,
                      'fa-database': true,
                      'fa-2x': true,
                      'text-success': storageUsage < 50,
                      'text-warning': storageUsage >= 50 && storageUsage < 75,
                      'text-danger': storageUsage >= 75,
                    }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body py-4" v-else>
            <b-alert variant="info" show
              ><div class="alert-message">
                <i class="fas fa-exclamation-circle"></i> Please select a
                project.
              </div></b-alert
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 d-flex">
        <div class="card flex-fill w-100">
          <div class="card-header">
            <h5 class="card-title mb-0">Project Usage</h5>
          </div>
          <div
            class="card-body d-flex w-100 overflow-auto"
            v-if="project.objectID"
          >
            <div class="align-self-center chart chart-lg">
              <apexchart
                type="line"
                height="300"
                :options="chartOptions"
                :series="series"
                class="mt-3"
                v-if="loaded"
              ></apexchart>
            </div>
          </div>
          <div class="card-body" v-else>
            <b-alert variant="info" show
              ><div class="alert-message">
                <i class="fas fa-exclamation-circle"></i> Please select a
                project.
              </div></b-alert
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 d-flex">
        <div class="card flex-fill w-100">
          <div class="card-header">
            <span class="badge badge-info float-right">{{
              ticketsNeedingAttention.length + ticketsOpen.length
            }}</span>
            <h5 class="card-title mb-0">Open Tickets</h5>
          </div>
          <div class="card-body overflow-auto tickets">
            <b-container fluid>
              <b-row
                v-if="
                  ticketsNeedingAttention.length > 0 || ticketsOpen.length > 0
                "
              >
                <b-col class="text-center">
                  <b-list-group>
                    <b-list-group-item
                      href="#"
                      class="flex-column align-items-start text-left"
                      v-for="(ticket, index) in ticketsNeedingAttention"
                      :key="index"
                      @click="readTicket(ticket['.key'])"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          #{{ ticket.id }}: {{ ticket.subject }}
                        </h5>
                        <small
                          >{{ formatDate(getLastUpdate(ticket.updates).date) }}
                        </small>
                      </div>

                      <p
                        class="mb-1"
                        v-html="
                          String(getLastUpdate(ticket.updates).message).substr(
                            0,
                            100
                          ) + '...'
                        "
                      ></p>

                      <small
                        v-html="
                          getLastUpdate(ticket.updates).type === 'new-message'
                            ? userAuthorName
                            : getLastUpdate(ticket.updates).author
                        "
                      ></small>
                    </b-list-group-item>
                    <b-list-group-item
                      href="#"
                      class="flex-column align-items-start text-left"
                      v-for="(ticket, index) in ticketsOpen"
                      :key="index"
                      @click="readTicket(ticket['.key'])"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          #{{ ticket.id }}: {{ ticket.subject }}
                        </h5>
                        <small
                          >{{ formatDate(getLastUpdate(ticket.updates).date) }}
                        </small>
                      </div>

                      <p
                        class="mb-1"
                        v-html="
                          String(getLastUpdate(ticket.updates).message).substr(
                            0,
                            100
                          )
                        "
                      ></p>

                      <small
                        v-html="
                          getLastUpdate(ticket.updates).type === 'new-message'
                            ? userAuthorName
                            : getLastUpdate(ticket.updates).author
                        "
                      ></small>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  ticketsNeedingAttention.length === 0 &&
                  ticketsOpen.length === 0
                "
              >
                <b-col class="text-center">
                  <h5 class="my-2 text-muted">You have no open tickets</h5>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import UsageMixin from "@/mixins/Usage";
import { DateTime } from "luxon";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Dashboard",
  mixins: [FirebaseMixin, UtilsMixin, UsageMixin],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      project: {},
      tickets: [],
      usage: {
        apis: {},
        fake: {},
        data: {},
      },
      data: {},
      loaded: false,
      series: [
        {
          name: "API Calls",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Fake Records",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
        {
          name: "Data Storage",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          background: "none",
        },
        theme: {
          mode: "dark",
          palette: "palette3",
        },
        // colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        // grid: {
        //   borderColor: "#e7e7e7",
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Usage (%)",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "userAuthorName"]),
    userUid() {
      return this.userProfile.uid;
    },
    ticketsNeedingAttention() {
      return this.tickets.filter((ticket) => {
        return ticket.status === "Needs Attention";
      });
    },
    ticketsOpen() {
      return this.tickets.filter((ticket) => {
        return ticket.status === "Open";
      });
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
        this.bindObject("tickets/" + this.userUid, null, "tickets");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.bindObject("usage", project.objectID, "usage").then(() => {
            let labels = [
              ...new Set([
                ...Object.keys(this.usage.apis),
                ...Object.keys(this.usage.fake),
                ...Object.keys(this.usage.data),
              ]),
            ].sort();
            let apiUsage = [];
            let fakeUsage = [];
            let storageUsage = [];
            let yMax = this.chartOptions.yaxis.max;
            // Add usage
            labels.forEach((label, i) => {
              // Add API call usage
              if (label in this.usage.apis) {
                apiUsage[i] = Number(
                  (this.usage.apis[label] / Number(project.plan.api_calls)) *
                    100
                ).toFixed(2);
                yMax = apiUsage[i] > yMax ? apiUsage[i] : yMax;
              } else {
                apiUsage[i] = 0;
              }
              // Add fake record usage
              if (label in this.usage.fake) {
                fakeUsage[i] = Number(
                  (this.usage.fake[label] / project.plan.fake) * 100
                ).toFixed(2);
                yMax = fakeUsage[i] > yMax ? fakeUsage[i] : yMax;
              } else {
                fakeUsage[i] = 0;
              }
              // Add storage usage
              if (label in this.usage.data) {
                storageUsage[i] = Number(
                  (this.usage.data[label] /
                    (project.plan.storage * 1024 * 1024)) *
                    100
                ).toFixed(2);
                yMax = storageUsage[i] > yMax ? storageUsage[i] : yMax;
              } else {
                storageUsage[i] = 0;
              }
            });
            // Update chart data
            this.chartOptions.xaxis.categories = [...labels];
            this.series[0].data = [...apiUsage];
            this.series[1].data = [...fakeUsage];
            this.series[2].data = [...storageUsage];
            this.chartOptions.yaxis.max = yMax;
            this.loaded = true;
          });
          this.bindObject("data", project.objectID, "data");
        }
      },
    },
  },
  methods: {
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toRelative();
    },
    readTicket(id) {
      this.navigate("read-ticket", { id: id });
    },
    getLastUpdate(updates) {
      const updatesArray = Object.values(updates);
      return updatesArray[updatesArray.length - 1];
    },
  },
  mounted() {
    this.chartOptions.theme.mode = document.body.dataset["theme"];
  },
};
</script>

<style lang="scss" scoped>
.tickets {
  max-height: 380px;
}

@media only screen and (min-width: 992px) {
  .col-flex {
    flex-basis: 0;
    flex-grow: 1;
  }
}
</style>
